<template>
  <el-dialog
    v-model="showDeleteSourceDialog"
    width="30%"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-source-delete-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-source-delete-dialog-header__title">
        {{ t('button.delete') }} {{ props.itemData?.name }}
      </h4>
    </template>
    <p class="elv-source-delete-dialog-content__title" :class="{ 'is-warning': props.tableType === 'JOURNAL_IMPORT' }">
      {{ transformI18n(infoTitle) }}
    </p>
    <p class="elv-source-delete-dialog-content__placeholder">{{ t('message.pleaseEnterSourceName') }}</p>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" @submit.prevent>
      <el-form-item prop="sourceName">
        <el-input ref="deleteInputRef" v-model="ruleForm.sourceName" />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="info" round class="elv-source-delete-dialog-footer__cancel" @click="onCancelDelete">{{
        t('button.cancel')
      }}</el-button>
      <el-button
        type="primary"
        round
        class="elv-source-delete-dialog-footer__delete"
        :loading="deleteLoading"
        @click="onDeleteSource"
        >{{ t('button.delete') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { trim } from 'lodash-es'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { $t, transformI18n } from '@/i18n/index'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  type: {
    type: String,
    default: 'Sources'
  },
  itemData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  tableType: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()

const ruleForm = reactive({
  sourceName: ''
})
const deleteLoading = ref(false)
const showDeleteSourceDialog = ref(false)
const deleteInputRef = ref()
const ruleFormRef = ref<FormInstance>()
const emit = defineEmits(['onUpdateSourceList'])

const rules = reactive<FormRules>({
  sourceName: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseEnterSourceName')}.`)
        }
        if (trim(value) !== trim(props.itemData?.name)) {
          return new Error(`${t('message.enterSourceName')}.`)
        }
        return true
      }
    }
  ]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const infoTitle = computed(() => {
  switch (props.tableType) {
    case 'JOURNAL_IMPORT':
      return $t('message.deletedJournalSourceInfo')
    default:
      return $t('message.deletedSourceInfo')
  }
})

// 切换删除弹窗展示
const onCheckDeleteDialog = () => {
  showDeleteSourceDialog.value = !showDeleteSourceDialog.value
}

const onCancelDelete = () => {
  ruleFormRef.value?.resetFields()
  ruleForm.sourceName = ''
  setTimeout(() => {
    emitter.emit('onFetchIntervalSourceList')
  }, 500)
  onCheckDeleteDialog()
}

const onDeleteSource = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        deleteLoading.value = true
        switch (props.tableType) {
          case 'JOURNAL_IMPORT':
            await AccountsApi.deleteJournalSource(entityId.value, props.itemData?.externalJournalSourceId)
            break
          default:
            await AccountsApi.deleteAccountSource(entityId.value, props.itemData?.sourceId)
            break
        }

        deleteLoading.value = false
        emit('onUpdateSourceList')
        if (props.type === 'account') {
          emitter.emit('resetAccountList')
        }
        ElMessage.warning(t('report.deleting'))
        onCancelDelete()
      } catch (error: any) {
        deleteLoading.value = false
        ElMessage.error(error?.message)
        console.log(error)
      }
    }
  })
}

defineExpose({ onCheckDeleteDialog })
</script>

<style lang="scss">
.elv-source-delete-dialog {
  width: 480px;
  height: 276px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;

    .elv-source-delete-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 23px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-source-delete-dialog-content__title {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-break: break-word;

      &.is-warning {
        color: #df2200;
      }
    }

    .elv-source-delete-dialog-content__placeholder {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .el-input {
      width: 432px;
      height: 44px;
      border-radius: 4px;
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }

      &.is_focus {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }
    }

    .el-form-item {
      margin-bottom: 0;

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-source-delete-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }

    .elv-source-delete-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
