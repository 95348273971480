<template>
  <el-form ref="sourceFormRef" v-loading="loading" :model="sourceForm" :rules="rules" label-position="top">
    <el-form-item :label="t('title.sourceName')" prop="name">
      <el-input v-model="sourceForm.name" />
    </el-form-item>
    <template v-if="isAPISource">
      <el-form-item
        v-for="item in currentPlatformProviderMap?.config ?? []"
        :key="item.key"
        :label="item.title"
        :prop="item.key"
      >
        <el-input
          v-if="item.type === 'input'"
          v-model.trim="sourceForm[item.key]"
          :minlength="item?.config?.validator?.length?.min ?? '—'"
          :maxlength="item?.config?.validator?.length?.max ?? '—'"
          :placeholder="item?.config?.placeholder ?? ''"
        />
        <el-input
          v-else-if="item.type === 'textarea'"
          v-model.trim="sourceForm[item.key]"
          :minlength="item?.config?.validator?.length?.min ?? '—'"
          :maxlength="item?.config?.validator?.length?.max ?? '—'"
          :autosize="{ minRows: 2 }"
          type="textarea"
          class="elv-accounts-sources-secret-textarea"
          :placeholder="item?.config?.placeholder ?? ''"
        />
        <el-checkbox-group
          v-else-if="item.type === 'checkbox'"
          v-model="sourceForm[item.key]"
          class="elv-accounts-sources-data-item-checkbox"
        >
          <el-checkbox
            v-for="(option, index) in item?.config?.options"
            :key="index"
            :checked="option?.checked ?? false"
            :label="option.label"
            :value="option.value"
          />
        </el-checkbox-group>
      </el-form-item>
    </template>

    <div v-if="currentPlatformProviderMap?.withTransaction" class="elv-accounts-data-select-content">
      <el-form-item :label="t('report.transactionDataOption')" prop="transactionOption.operator">
        <el-select
          v-model="sourceForm.transactionOption.operator"
          placeholder=" "
          :disabled="props.model === 'edit'"
          popper-class="elv-accounts-dialog-popper"
          :class="{
            'elv-accounts-sources-data-short-select':
              sourceForm.transactionOption.operator !== 'ALL' && sourceForm.transactionOption.operator !== 'BETWEEN',
            'elv-accounts-sources-data-shorter-select': sourceForm.transactionOption.operator === 'BETWEEN'
          }"
          @change="onChangeOperator"
        >
          <el-option
            v-for="(item, index) in transactionDataOptions"
            :key="index"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="sourceForm.transactionOption.operator !== 'ALL'"
        class="elv-accounts-form-item-date"
        prop="value"
      >
        <div
          ref="dateStartSelectRef"
          v-click-outside="onCloseStartDateTime"
          class="elv-accounts-date-select"
          :class="{
            'is-disabled': props.model === 'edit',
            'is-short': sourceForm.transactionOption.operator === 'BETWEEN',
            'is-focus': visibleStartPopover
          }"
          @click="onOpenStartDateTime()"
        >
          <div v-if="sourceForm.transactionOption.value[0]" class="elv-accounts-date-time-value">
            <span>{{
              sourceForm.transactionOption.value[0]
                ? dateFormatting(sourceForm.transactionOption.value[0], 'date', '-')
                : ''
            }}</span>
          </div>
          <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: startArrowRotate }" />
        </div>

        <el-date-picker
          ref="datePickerStartPopoverRef"
          v-model="sourceForm.transactionOption.value[0]"
          placement="bottom-start"
          popper-class="elv-accounts-date-popper"
          :virtual-ref="dateStartSelectRef"
          virtual-triggering
          type="date"
          value-format="YYYY-MM-DD"
          :clearable="false"
          :disabled="props.model === 'edit'"
          @change="onChangeDate('start')"
        />
        <template v-if="sourceForm.transactionOption.operator === 'BETWEEN'">
          <span class="elv-accounts-date-scope">{{ t('common.to') }}</span>
          <div
            ref="dateEndSelectRef"
            v-click-outside="onCloseEndDateTime"
            class="elv-accounts-date-select is-short"
            :class="{ 'is-disabled': props.model === 'edit', 'is-focus': visibleEndPopover }"
            @click="onOpenEndDateTime"
          >
            <div v-if="sourceForm.transactionOption.value[1]" class="elv-accounts-date-time-value">
              <span>{{
                sourceForm.transactionOption.value[1]
                  ? dateFormatting(sourceForm.transactionOption.value[1], 'date', '-')
                  : ''
              }}</span>
            </div>
            <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: endArrowRotate }" />
          </div>

          <el-date-picker
            ref="datePickerEndPopoverRef"
            v-model="sourceForm.transactionOption.value[1]"
            placement="bottom-start"
            popper-class="elv-accounts-date-popper"
            :virtual-ref="dateEndSelectRef"
            virtual-triggering
            type="date"
            value-format="YYYY-MM-DD"
            :clearable="false"
            :disabled="props.model === 'edit'"
            @change="onChangeDate('end')"
          />
        </template>
      </el-form-item>
    </div>
  </el-form>
  <div class="el-dialog__footer">
    <elv-button
      type="primary"
      round
      height="44"
      width="113"
      class="elv-accounts-dialog-footer__button"
      :loading="submitLoading"
      :disabled="disabledSave"
      @click="onClickConnect"
      >{{ t('button.save') }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import AccountsApi from '@/api/AccountsApi'
import { dateFormatting } from '@/lib/utils'
import { transformI18n } from '@/i18n/index'
import { PlatformProviderMapType } from '#/EntityTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { transactionDataOptions } from '@/config/reports'
import { ThirdPartyOpenAuthType } from '#/AccountsTypes'
import type { FormInstance, FormRules } from 'element-plus'
import { trim, find, pick, isEqual, cloneDeep, isEmpty } from 'lodash-es'
import { ElMessage, ClickOutside as vClickOutside } from 'element-plus'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  type: {
    type: String,
    default: 'CHAIN'
  },
  showDialog: {
    type: Boolean,
    default: false
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  thirdPartyOpenAuthTypeData: {
    type: Object as PropType<ThirdPartyOpenAuthType>,
    required: true
  }
})
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const emit = defineEmits(['updateAccountDetail'])

const sourceForm: any = reactive({
  name: '',
  platformId: 0,
  entityAccountId: 0,
  transactionOption: {
    operator: 'ALL',
    value: []
  }
})

const datePickerStartPopoverRef = ref()
const datePickerEndPopoverRef = ref()
const dateStartSelectRef = ref()
const dateEndSelectRef = ref()
const loading = ref(false)
const submitLoading = ref(false)
const visibleStartPopover = ref(false)
const visibleEndPopover = ref(false)
const startArrowRotate = ref('rotate(90deg)')
const endArrowRotate = ref('rotate(90deg)')
const sourceFormRef = ref<FormInstance>()

const selectedPlatform = computed(() => {
  return find(entityStore.platformList, { platformId: sourceForm.platformId })
})

const rules = reactive<FormRules>({
  type: {
    required: true,
    trigger: 'blur'
  },
  name: {
    required: true,
    trigger: 'blur',
    message: t('message.pleaseEnterSourceName')
  },
  'transactionOption.operator': {
    required: true,
    trigger: 'blur',
    message: 'Transaction data is required'
  },
  value: {
    required: true,
    trigger: 'blur',
    validator: (rule: any, value: any, callback: any) => {
      if (sourceForm.transactionOption.operator === 'BETWEEN') {
        if (
          (sourceForm.transactionOption.value.length < 2 && !sourceForm.transactionOption.value[0],
          !sourceForm.transactionOption.value[1])
        ) {
          callback(new Error('Date is required'))
        } else if ((sourceForm.transactionOption.value[0], sourceForm.transactionOption.value[1])) {
          if (dayjs(sourceForm.transactionOption.value[0]).isAfter(sourceForm.transactionOption.value[1])) {
            callback(new Error('Start date must be less than end date'))
          } else {
            callback()
          }
        }
      } else if (sourceForm.transactionOption.operator !== 'BETWEEN' && !sourceForm.transactionOption.value[0]) {
        callback(new Error('Date is required'))
      } else {
        callback()
      }
    }
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const isAPISource = computed(() => {
  return props.type !== 'CHAIN' && (props.type !== 'BANK' || selectedPlatform.value?.collectorDriver !== 'NONE')
})

const dataIntegrationOptions = computed(() => {
  return props.currentData?.platform?.platformProviderMapList ?? []
})

const currentPlatformProviderMap = computed<PlatformProviderMapType>(() => {
  return (
    find(dataIntegrationOptions.value, { providerId: props.currentData?.provider?.providerId || '' }) ??
    ({} as PlatformProviderMapType)
  )
})
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const disabledSave = computed(() => {
  return (
    (props.model === 'add' &&
      (!sourceForm.name ||
        !sourceForm.platformId ||
        (isAPISource.value &&
          currentPlatformProviderMap.value?.config.some((config) => {
            return config?.config?.validator?.required && !sourceForm[config?.key]
          })) ||
        (sourceForm.transactionOption.operator === 'BETWEEN' &&
          (!sourceForm.transactionOption.value[0] || !sourceForm.transactionOption.value[1])) ||
        (sourceForm.transactionOption.operator !== 'ALL' && !sourceForm.transactionOption.value[0]))) ||
    (props.model === 'edit' &&
      sourceForm.name === props.currentData.name &&
      isAPISource.value &&
      sourceForm.apiKey !== props.currentData.apiKey &&
      sourceForm.apiSecret === props.currentData.apiSecret &&
      sourceForm.transactionOption.operator === props.currentData?.transactionOption?.operator &&
      isEqual(sourceForm.transactionOption.value, props.currentData?.transactionOption?.value)) ||
    (sourceForm.transactionOption.operator === 'BETWEEN' &&
      (!sourceForm.transactionOption.value[0] || !sourceForm.transactionOption.value[1])) ||
    (sourceForm.transactionOption.operator !== 'ALL' && !sourceForm.transactionOption.value[0])
  )
})

const onChangeOperator = () => {
  sourceForm.transactionOption.value = []
}

const onOpenStartDateTime = () => {
  if (props.model === 'edit') return
  visibleStartPopover.value = !visibleStartPopover.value
  startArrowRotate.value = visibleStartPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleStartPopover.value) {
    unref(datePickerStartPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerStartPopoverRef)?.handleClose?.()
  }
}

const onCloseStartDateTime = () => {
  visibleStartPopover.value = false
  unref(datePickerStartPopoverRef)?.handleClose?.()
  startArrowRotate.value = 'rotate(90deg)'
}

const onCloseEndDateTime = () => {
  visibleEndPopover.value = false
  unref(datePickerEndPopoverRef)?.handleClose?.()
  endArrowRotate.value = 'rotate(90deg)'
}

const onOpenEndDateTime = () => {
  if (props.model === 'edit') return
  visibleEndPopover.value = !visibleEndPopover.value
  endArrowRotate.value = visibleEndPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleEndPopover.value) {
    unref(datePickerEndPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerEndPopoverRef)?.handleClose?.()
  }
}

const resetFrom = () => {
  sourceFormRef.value?.resetFields()
}

const onChangeDate = (scope: string) => {
  if (scope === 'start') {
    visibleStartPopover.value = false
    startArrowRotate.value = 'rotate(90deg)'
  } else {
    visibleEndPopover.value = false
    endArrowRotate.value = 'rotate(90deg)'
  }
  sourceFormRef.value?.validateField('value')
}

const onClickConnect = async () => {
  if (!sourceFormRef.value) return
  await sourceFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        // eslint-disable-next-line no-nested-ternary
        const data: any = pick(sourceForm, ['name', 'entityAccountId'])
        if (isAPISource.value) {
          data.configValues = currentPlatformProviderMap.value?.config?.map((config) => {
            return {
              key: config.key,
              value: sourceForm[config.key]
            }
          })
        }
        data.type = props.type === 'CHAIN' ? 'WALLET' : props.type
        data.name = trim(sourceForm.name)
        data.providerId = props.currentData?.provider?.providerId
        if (
          ((props.model === 'edit' &&
            sourceForm.transactionOption.operator !== props.currentData.transactionOption?.operator) ||
            props.model === 'add') &&
          currentPlatformProviderMap.value?.withTransaction
        ) {
          data.transactionOption = {
            operator: sourceForm.transactionOption.operator,
            value: sourceForm.transactionOption.operator === 'ALL' ? null : sourceForm.transactionOption.value
          }
        }

        if (props.model === 'edit') {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.dataSource?.update
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          delete data.entityAccountId
          delete data.type
          delete data.providerId
          if (props.currentData?.sourceId) {
            await AccountsApi.editAccountSource(entityId.value, props.currentData?.sourceId, data)
          } else if (props.currentData?.externalJournalSourceId) {
            await AccountsApi.editJournalSource(entityId.value, props.currentData?.externalJournalSourceId, {
              name: data.name
            })
          }
        } else {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.dataSource?.create
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          if (props.thirdPartyOpenAuthTypeData.thirdPartyOpenAuthDataId) {
            data.thirdPartyOpenAuthDataId = props.thirdPartyOpenAuthTypeData.thirdPartyOpenAuthDataId
          }
          await AccountsApi.addAccountSource(entityId.value, data)
        }
        // const syncData = {
        //   entityId: entityId.value,
        //   sourceName: sourceForm.accountName,
        //   type: 'EXCHANGE'
        // }
        sourceFormRef.value?.resetFields()
        ElMessage.success(t('message.saveSuccess'))
        emit('updateAccountDetail')
        // if (props.model === 'add' || (props.model === 'edit' && data?.transactionOption?.operator)) {
        //   reportStore.editSourceSync(entityId.value, true, syncData)
        //   emitter.emit('onStartSync')
        // }
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({
  resetFrom
})

watchEffect(async () => {})

watch(
  [() => props.currentData, () => props.showDialog],
  async () => {
    if (props.showDialog) {
      const currentSourceData = cloneDeep(props.currentData)
      sourceForm.platformId = currentSourceData?.platform?.platformId || 0
      sourceForm.entityAccountId = currentSourceData?.entityAccountId || 0
      if (props.model === 'edit') {
        sourceForm.name = currentSourceData?.name
        if (isAPISource.value) {
          try {
            loading.value = true
            const { data } = await AccountsApi.getAccountSourceDetail(entityId.value, currentSourceData?.sourceId)
            if (data.configValues?.length) {
              data.configValues.forEach((configValue: any) => {
                sourceForm[configValue?.key] = configValue?.value
              })
            }
          } catch (error) {
            console.log(error)
          } finally {
            loading.value = false
          }
        }
        sourceForm.transactionOption.operator = currentSourceData?.transactionOption?.operator ?? 'ALL'
        sourceForm.transactionOption.value =
          currentSourceData?.transactionOption?.value === null ? [] : currentSourceData?.transactionOption?.value
      } else {
        sourceForm.transactionOption.operator = 'ALL'
        sourceForm.transactionOption.value = []
      }
    }
  },
  { immediate: true }
)

watch(
  () => currentPlatformProviderMap.value,
  () => {
    if (props.showDialog && !isEmpty(props.currentData.platform) && currentPlatformProviderMap.value?.config?.length) {
      currentPlatformProviderMap.value?.config.forEach((config) => {
        sourceForm[config?.key] = config?.type === 'checkbox' ? [] : ''
        if (config?.config?.validator?.required) {
          rules[config?.key] = {
            required: true,
            trigger: 'blur',
            message: `${config?.title} is required`
          }
        }
      })
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-accounts-sources-data-item-checkbox {
  display: flex;
  flex-direction: column;

  .el-checkbox {
    min-height: 28px;
    height: fit-content;
    margin-right: 0;
    max-width: 572px;
    display: flex;
    align-items: flex-start;

    .el-checkbox__input {
      width: 16px;
      height: 16px;
      transform: translateY(2px);
    }

    .el-checkbox__label {
      white-space: pre-wrap;
      line-height: normal;
      max-width: calc(100% - 24px);
    }
  }
}
</style>
